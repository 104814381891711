import React, { useState, useEffect, useRef } from 'react';
import { 
  Container, 
  TextField, 
  Button, 
  Typography, 
  Box, 
  Slider, 
  IconButton, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  createTheme, 
  ThemeProvider, 
  Autocomplete, 
  Chip, 
  Fab, 
  Stack, 
  SwipeableDrawer, 
  // Skeleton, 
  // styled,
  ClickAwayListener,
  Grow,
  Grid,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  ListItemIcon
} from '@mui/material';

// import { grey } from '@mui/material/colors';
// import RadioIcon from '@mui/icons-material/Radio';
import StarIcon from '@mui/icons-material/Star';
import PaletteIcon from '@mui/icons-material/Palette';

import { toCanvas } from 'qrcode'
import { Global } from '@emotion/react';
import { SketchPicker } from 'react-color';

// const drawerBleeding = 56;
const drawerBleeding = 0;

// const StyledBox = styled(Box)(({ theme }) => ({
//   backgroundColor: theme.palette.background.paper,
//   width: '100vw',
// }));

const getContrastColor = (theme) => {
  return theme.palette.primary.contrastText
};

function App() {
  const [query, setQuery] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [countries, setCountries] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [stations, setStations] = useState([]);
  const [currentStationIndex, setCurrentStationIndex] = useState(0);
  const [audioError, setAudioError] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [loading, setLoading] = useState(false);
  const [volume, setVolume] = useState(() => {
    const savedVolume = localStorage.getItem('volume');
    return savedVolume !== null ? parseInt(savedVolume, 10) : 50;
  });
  const [primaryColor, setPrimaryColor] = useState('#000000');
  // const [contrastTextPrimaryColor] = useState('#ffffff');
  // const [secondaryColor] = useState('#64DD17');
  // const [contrastTextSecondaryColor] = useState('#ffffff');
  const [showPlayer, setShowPlayer] = useState(false);
  const [showMuteDialog, setShowMuteDialog] = useState(false);
  const [showQRCodeDialog, setShowQRCodeDialog] = useState(false);
  const [initialStation, setInitialStation] = useState(null);
  const audioRef = useRef(null);
  const qrCodeRef = useRef(null);
  const menuAnchorRef = React.useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [colorPickerOpen, setColorPickerOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState(''); 
  const [favorites, setFavorites] = useState([])
  const [filteredFavorites, setFilteredFavorites] = useState([])
  const [theme, setTheme] = useState(createTheme({
    palette: {
      primary: {
        main: primaryColor
      }
    }
  }))

  // const [currentFavoriteIndex, setCurrentFavoriteIndex] = useState(0);

  const reloadFavorites = () => {
    try { 
      const savedFavorites = localStorage.getItem('favorites');
      const newFavorites = JSON.parse(savedFavorites)
      setFavorites(newFavorites ? newFavorites : []);
    } catch (err) {
      console.log("could not parse favorites...")
    }
  }

  const handleColorChange = (newColor) => {
    setPrimaryColor(newColor.hex)
    localStorage.setItem('color', newColor.hex);
  }

  useEffect(() => {
    reloadFavorites()
  }, []);

  useEffect(() => {
    const filterFavorites = () => {
      if(searchTerm.length === 0 ) {
        setFilteredFavorites(favorites)
      } else {
        const newFilteredFavorites = favorites.filter(favorite =>
          favorite.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
  
        setFilteredFavorites(newFilteredFavorites)
      }
    }

    filterFavorites()
  }, [searchTerm, favorites]);

  useEffect(() => {
    const newTheme = createTheme({
      palette: {
        primary: {
          main: primaryColor,
          // contrastText: contrastTextPrimaryColor
        },
        // secondary: {
        //   main: secondaryColor,
        //   contrastText: contrastTextSecondaryColor
        // }
      },
    })

    setTheme(newTheme)
  }, [primaryColor])

  useEffect(() => {
    const fetchCountries = async () => {
      const response = await fetch('https://de1.api.radio-browser.info/json/countries');
      const countries = await response.json();
      const uniqueCountries = [...new Map(countries.map(country => [country.name, country])).values()];
      const cleanedCountries = uniqueCountries
        .filter(country => country.stationcount > 0)
        .sort((a, b) => a.name.localeCompare(b.name));
      setCountries(cleanedCountries);
    };

    const fetchTags = async () => {
      const response = await fetch('https://de1.api.radio-browser.info/json/tags?hidebroken=true&order=name');
      const tags = await response.json();
      const cleanedTags = tags
        .filter(tag => tag.stationcount > 0)
        .sort((a, b) => a.name.localeCompare(b.name));
      setTags(cleanedTags);
    };

    fetchCountries();
    fetchTags();

    // Check for radio ID in query parameters
    const urlParams = new URLSearchParams(window.location.search);
    const radioId = urlParams.get('id');
    if (radioId) {
      fetchStationById(radioId);
    }

    const queryColor = urlParams.get('color');
    const storageColor = localStorage.getItem('color');
    if (queryColor) {
      setPrimaryColor(`#${queryColor}`);
      localStorage.setItem('color', `#${queryColor}`);
    } else if (storageColor) {
      setPrimaryColor(`${storageColor}`);
    }
  }, []);

  const fetchStationById = async (id) => {
    const response = await fetch(`https://de1.api.radio-browser.info/json/stations/byuuid/${id}`);
    const stationList = await response.json();
    if (stationList && stationList.length > 0) {
      setStations([stationList[0]]);
      setInitialStation(stationList[0]);
      setPlaying(false); // Ensure we do not attempt to play immediately
      setShowPlayer(true);
      setShowMuteDialog(true);
    } else {
      alert('Station not found.');
    }
  };

  const searchRadioStation = async (query, countryCode, tags) => {
    let url = 'https://de1.api.radio-browser.info/json/stations/search?limit=10';
    if (query) {
      url += `&name=${query}&nameExact=false`;
    }
    if (countryCode) {
      url += `&countrycode=${countryCode}&has_geo_info=true`;
    }
    if (tags.length > 0) {
      url += `&tagList=${tags.join(',')}`;
    }
    const response = await fetch(url);
    const stations = await response.json();
    if (stations.length > 0) {
      return stations;
    }
    return null;
  };

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
  };

  const handleCountryChange = (event, value) => {
    setCountryCode(value ? value.iso_3166_1 : '');
  };

  const handleTagChange = (event, value) => {
    setSelectedTags(value.map(tag => tag.name));
  };

  const handleSearchClick = async () => {
    setStations([]);
    setCurrentStationIndex(0);
    setAudioError(false);
    setPlaying(false);
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.src = '';
    }
    const stations = await searchRadioStation(query, countryCode, selectedTags);
    if (stations) {
      setStations(stations);
      setPlaying(true);
      setShowPlayer(true);
      playStation(stations[0], false);
    } else {
      alert('No stations found.');
    }
  };

  const playStation = (station, mute = false) => {
    if (audioRef.current) {
      audioRef.current.src = station.url_resolved;
      audioRef.current.load();
      setLoading(true);
      if (mute) {
        audioRef.current.muted = true;
        setVolume(0);
        setShowMuteDialog(true);
      } else {
        audioRef.current.muted = false;
        audioRef.current.volume = volume / 100;
        audioRef.current.play().then(() => {
          setPlaying(true);
          setLoading(false);
        }).catch((error) => {
          console.error('Error attempting to play:', error);
          setAudioError(true);
          setLoading(false);
        });
      }
    }
  };

  const handlePlayPauseClick = () => {
    if (playing) {
      audioRef.current.pause();
      setPlaying(false);
    } else {
      playStation(stations[currentStationIndex]);
    }
  };

  const handleVolumeChange = (event, newValue) => {
    setVolume(newValue);
    if (audioRef.current) {
      audioRef.current.volume = newValue / 100;
      audioRef.current.muted = newValue === 0;
    }
    localStorage.setItem('volume', newValue);
  };

  const handleVolumeOffClick = () => {
    setVolume(0);
    if (audioRef.current) {
      audioRef.current.volume = 0;
      audioRef.current.muted = true;
    }
    localStorage.setItem('volume', 0);
  };

  const handleVolumeUpClick = () => {
    setVolume(100);
    if (audioRef.current) {
      audioRef.current.volume = 1;
      audioRef.current.muted = false;
    }
    localStorage.setItem('volume', 100);
  };

  const handleAudioError = () => {
    setAudioError(true);
  };

  const handleNextStation = () => {
    setCurrentStationIndex((prevIndex) => {
      const newIndex = (prevIndex + 1) % stations.length;
      playStation(stations[newIndex]);
      return newIndex;
    });
  };

  const handlePreviousStation = () => {
    setCurrentStationIndex((prevIndex) => {
      const newIndex = (prevIndex - 1 + stations.length) % stations.length;
      playStation(stations[newIndex]);
      return newIndex;
    });
  };

  const handleFilterClick = () => {
    setShowPlayer(false);
  };

  const handleShareClick = () => {
    const station = stations[currentStationIndex];
    if (station) {
      const shareUrl = `${process.env.REACT_APP_URL}?id=${station.stationuuid}`;
      if (navigator.share) {
        navigator.share({
          title: `Listen to ${station.name ? station.name.trim() + ' ! ' : ` ${process.env.REACT_APP_TITLE} ! `}`,
          url: shareUrl,
        }).catch((error) => console.error('Error sharing:', error));
      } else {
        navigator.clipboard.writeText(shareUrl).then(() => {
          alert('Station link copied to clipboard ! ');
        }).catch((error) => {
          console.error('Error copying link:', error);
          alert('Failed to copy link.');
        });
      }
    }
  };

  const isFavorited = () => {
    const currentStation = stations[currentStationIndex]

    if(!currentStation) {
      return false 
    }

    return favorites.some(fav => fav.stationuuid === currentStation.stationuuid)
  }

  const toggleFavoriteClick = () => {
    const station = stations[currentStationIndex];

    if (!station) return 

    const newFavorites = favorites;
    const favoriteIndex = favorites.findIndex(fav => fav.stationuuid === station.stationuuid)
    if(favoriteIndex !== -1) {
      newFavorites.splice(favoriteIndex, 1)
    } else {
      newFavorites.push(station) 
      newFavorites.sort((f1,f2) => f1.name <= f2.name)
    }

    localStorage.setItem('favorites', JSON.stringify(newFavorites));

    reloadFavorites()
  };

  const toggleMenu = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setMenuOpen(open);
  };

  const toggleDrawer = (open) => {
    if(!open) {
      setSearchTerm("")
    }

    setDrawerOpen(open);
  };

  const handleFavoriteSelect = (station) => {
    setStations([station]);
    setCurrentStationIndex(0);
    setShowPlayer(true);
    playStation(station);
    toggleDrawer(false)
  };

  const handleShazamClick = () => {
    if (navigator.userAgent.match(/Android/i)) {
      window.location.href = "intent://#Intent;package=com.shazam.android;scheme=shazam;end";
    } else if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
      window.location.href = "shazam://";
    } else {
      setShowQRCodeDialog(true);
      setTimeout(() => generateQRCode(), 100);
    }
  };

  const generateQRCode = () => {
    const qrCodeElement = qrCodeRef.current;
    if (qrCodeElement) {
      qrCodeElement.innerHTML = '';
      toCanvas('text', { 
        widtherrorCorrectionLevel: 'H',
        width: 128,
        height: 128,
        colorDark : "#000000",
        colorLight : "#ffffff",
      }, function (err, canvas) {
        if (err) throw err
        qrCodeElement.appendChild(canvas)
      })

      // toCanvas(qrCodeElement, 'https://www.shazam.com/apps', function (error) {
      //   debugger
      //   if (error) console.error(error)
      //   console.log('success!');
      // })    
    }
  };

  // const scrollFavorites = (direction) => {
  //   setCurrentFavoriteIndex((prevIndex) => {
  //     const newIndex = direction === 'left'
  //       ? Math.max(prevIndex - 1, 0)
  //       : Math.min(prevIndex + 1, favorites.length - 1);
  //     const container = document.querySelector('.favorites-list');
  //     container.style.transform = `translateX(-${newIndex * 120}px)`;
  //     return newIndex;
  //   });
  // };

  const handleMuteDialogClose = () => {
    setShowMuteDialog(false);
  };

  const handleQRCodeDialogClose = () => {
    setShowQRCodeDialog(false);
  };

  const handleFavoriteMenuClick = () => {
    setMenuOpen(false);
    setDrawerOpen(true)    
  };

  const handleColorMenuClick = () => {
    setMenuOpen(false);
    setColorPickerOpen(true)
  }

  const handleUnmute = () => {
    const savedVolume = localStorage.getItem('volume');
    setVolume(savedVolume ? parseInt(savedVolume, 10) : 50);
    if (initialStation) {
      playStation(initialStation);
    }
    setShowMuteDialog(false);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  }

  useEffect(() => {
    setAudioError(false);
    if (audioRef.current) {
      audioRef.current.volume = volume / 100;
    }
  }, [volume]);

  return (
    <ThemeProvider theme={theme}>
      <Container sx={{ 
        padding: '20px', 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center', 
        height: '100vh' 
      }}>
        <Fab color="primary" className="fab-button" ref={menuAnchorRef} onClick={toggleMenu(true)} sx={{position:'fixed', top:'20px',left:'20px'}}>
          <span className="material-icons">menu</span>
        </Fab>
        <Popper
          open={menuOpen}
          anchorEl={menuAnchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={toggleMenu(false)}>
                  <MenuList
                    autoFocusItem={menuOpen}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                  >
                    <MenuItem onClick={handleFavoriteMenuClick}>
                      <ListItemIcon>
                        <StarIcon fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="inherit">Favorites</Typography>
                    </MenuItem>
                    <MenuItem onClick={handleColorMenuClick}>
                      <ListItemIcon>
                        <PaletteIcon fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="inherit">Color</Typography>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        {showPlayer && <Fab color="primary" className="fab-button" onClick={handleFilterClick} sx={{position:'fixed', top:'20px',right:'20px'}}>
          <span className="material-icons">search</span>
        </Fab>}
        <Typography variant="h1" className="app-title" color='primary'>{process.env.REACT_APP_TITLE}</Typography>
        <Box sx={{ marginTop: '65px', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
          {!showPlayer && (
            <Box className="form-container">
              <TextField
                label="Search (e.g., radio name, city, genre)"
                variant="outlined"
                fullWidth
                value={query}
                onChange={handleQueryChange}
                InputProps={{
                  sx: {
                    borderRadius: '25px',
                  },
                }}
                InputLabelProps={{
                  sx: {
                    borderRadius: '25px',
                  },
                }}
                sx={{ mb: 2 }}
              />
              <Autocomplete
                options={countries}
                getOptionLabel={(option) => option.name}
                onChange={handleCountryChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Country"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      sx: {
                        borderRadius: '25px',
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        borderRadius: '25px',
                      },
                    }}
                    sx={{ mb: 2 }}
                  />
                )}
                sx={{ width: '100%' }}
              />
              <Autocomplete
                multiple
                options={tags}
                getOptionLabel={(option) => option.name}
                value={tags.filter(tag => selectedTags.includes(tag.name))}
                onChange={handleTagChange}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      label={option.name}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Tags"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      sx: {
                        borderRadius: '25px',
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        borderRadius: '25px',
                      },
                    }}
                    sx={{ mb: 2 }}
                  />
                )}
                sx={{ width: '100%' }}
              />
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button startIcon={<span className="material-icons">radio</span>} variant="contained" color="primary" onClick={handleSearchClick} sx={{ flex: 1, borderRadius: '25px', height: '56px' }}>
                  Play Radio
                </Button>
              </Box>
            </Box>
          )}
          {showPlayer && (
            <>
              <Box id="player" sx={{ mt: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                <Box className="favicon-container">
                  <img src={stations[currentStationIndex]?.favicon || 'https://via.placeholder.com/80'} alt="Radio Favicon" className="favicon" />
                </Box>
                <Typography variant="h6" component="div">
                  {stations[currentStationIndex]?.name || 'No Station Selected'}
                </Typography>
                <Typography variant="subtitle1" color="text.secondary" component="div">
                  {stations[currentStationIndex]?.country || ''}
                </Typography>
                <Box className="button-group" sx={{ mt: 2 }}>
                  <IconButton className="nav-button" onClick={handlePreviousStation} style={{ backgroundColor: primaryColor, color: getContrastColor(theme) }}>
                    <span className="material-icons">arrow_back</span>
                  </IconButton>
                  <IconButton className="play-stop-button" onClick={handlePlayPauseClick} style={{ backgroundColor: primaryColor, color: getContrastColor(theme) }}>
                    {loading ? <div className="loader" style={{ borderColor: getContrastColor(theme), borderTopColor: primaryColor }}></div> : playing ? <span className="material-icons">stop</span> : <span className="material-icons" style={{ color: getContrastColor(theme) }}>play_arrow</span>}
                  </IconButton>
                  <IconButton className="nav-button" onClick={handleNextStation} style={{ backgroundColor: primaryColor, color: getContrastColor(theme) }}>
                    <span className="material-icons">arrow_forward</span>
                  </IconButton>
                </Box>
                <Box sx={{ mt: 2, width: '80%' }}>
                  <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                      <span className="material-icons" style={{ cursor: 'pointer' }} onClick={handleVolumeOffClick}>volume_off</span>
                      <Slider aria-label="Volume" value={volume} onChange={handleVolumeChange} sx={{ width: '250px', height: '8px' }} />
                      <span className="material-icons" style={{ cursor: 'pointer' }} onClick={handleVolumeUpClick}>volume_up</span>
                    </Stack>
                  </Box>
                </Box>
                <audio ref={audioRef} onError={handleAudioError} />
                {audioError && (
                  <Typography color="error" sx={{ mt: 2 }}>
                    Unable to play the stream. Please try another station.
                  </Typography>
                )}
              </Box>
              <Box style={{ 
                // position: 'fixed', 
                // bottom: '0', 
                // left: '0', 
                width:'100vw', 
                height: '100px',
                // color: getContrastColor(theme),
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '30px'
              }}>
                <Fab color="primary" className="fab-button" onClick={handleShareClick}>
                  <span className="material-icons">share</span>
                </Fab>
                {isFavorited() && <Fab className="fab-button" onClick={toggleFavoriteClick} style={{ color: primaryColor, backgroundColor: getContrastColor(theme) }}>
                  <span className="material-icons">star</span>
                </Fab>}
                {!isFavorited() && <Fab color="primary" className="fab-button" onClick={toggleFavoriteClick}>
                  <span className="material-icons">star</span>
                </Fab>}
                <Fab color="primary" className="fab-button shazam-button" onClick={handleShazamClick}>
                  <span className="material-icons">question_mark</span>
                </Fab>
                {/* <Fab color="primary" className="fab-button" onClick={handleShazamClick}>
                  <span className="material-icons">search</span>
                </Fab> */}
              </Box>
            </>
          )}
          <Dialog open={colorPickerOpen} onClose={() => { setColorPickerOpen(false) }}>
            <DialogTitle>Choose a Color</DialogTitle>
            <DialogContent style={{ padding: 0 }}>
              <SketchPicker color={primaryColor} onChangeComplete={handleColorChange} />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => { setColorPickerOpen(false) }} color="primary">
                Done
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
        <Global
          styles={{
            '.MuiDrawer-root > .MuiPaper-root': {
              height: `calc(50% - ${drawerBleeding}px)`,
              overflow: 'visible',
            },
          }}
        />

        <SwipeableDrawer
          id="swipeable-drawer"
          anchor="bottom"
          open={drawerOpen}
          onClose={() => {toggleDrawer(false)}}
          onOpen={() => {toggleDrawer(true)}}
          swipeAreaWidth={drawerBleeding}
          disableSwipeToOpen={false}
          classes={{
            paper: 'MuiDrawer-paper',
          }}
        >
          <div className="drawer-content">
            <div className="sticky-header">
              <div className="puller" />
              <div className="sticky-container">
                <Typography variant="h6" className="title">
                  Favorites
                </Typography>
                <TextField
                  label="Search"
                  variant="outlined"
                  fullWidth
                  value={searchTerm}
                  onChange={handleSearchChange}
                  InputProps={{
                    sx: {
                      borderRadius: '25px'
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      borderRadius: '25px',
                    },
                  }}
                  sx={{ mb: 2 }}
                />
              </div>
            </div>
            <Grid container spacing={2} className="gallery">
              {filteredFavorites.map((favorite, index) => (
                <Grid item xs={6} sm={4} md={3} lg={2} className="image-container" key={favorite.stationuuid} onClick={() => handleFavoriteSelect(favorite)}>
                  <img src={favorite.favicon || 'https://via.placeholder.com/50'} alt={favorite.name} className="image" />
                  <Typography variant="body2" className="image-title">{favorite.name}</Typography>
                </Grid>
              ))}
            </Grid>
          </div>
        </SwipeableDrawer>
      </Container>
      <Dialog open={showMuteDialog} onClose={handleMuteDialogClose}>
        <DialogTitle sx={{ textAlign: 'center' }}>Radio Muted</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <span className="material-icons" style={{ fontSize: '48px', color: primaryColor }}>volume_off</span>
            <Typography variant="body1" align="center">The Radio is Muted. Please take an action.</Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: '15px' }}>
          <Button variant="outlined" color="primary" onClick={handleMuteDialogClose} startIcon={<span className="material-icons">volume_off</span>} sx={{ flex: 1, marginRight: '8px' }}>
            Keep Muted
          </Button>
          <Button variant="contained" color="primary" onClick={handleUnmute} startIcon={<span className="material-icons">volume_up</span>} sx={{ flex: 1 }}>
            Unmute Now
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showQRCodeDialog} onClose={handleQRCodeDialogClose}>
        <DialogTitle sx={{ textAlign: 'center' }}>Scan to Shazam</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div id="qrcode" ref={qrCodeRef}></div>
            <Typography variant="body1" align="center">Scan the QR code to open Shazam and get information about what you are listening</Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: '15px' }}>
          <Button variant="contained" color="primary" onClick={handleQRCodeDialogClose} startIcon={<span className="material-icons">close</span>} sx={{ flex: 1 }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}

export default App
